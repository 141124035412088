@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

@layer components {
    .card-title {
        @apply font-bold text-lg mb-5;
    }

    .m_btn {
        @apply bg-blue-900 text-white text-xs font-bold py-1 px-3 rounded;
    }

    .form-input {
        @apply border outline-none rounded-lg p-1;
    }

    .empty-space {
        @apply flex justify-center items-center w-full h-32 border-2 border-dashed;
    }

    .action-btn {
        @apply bg-green-600 text-white font-bold rounded px-4 py-2 my-3;
    }

    .file-upload-container {
        @apply w-full overflow-y-auto border-2 border-dashed border-green-600 relative flex justify-center items-center;
    }

    .file-upload-input {
        @apply absolute inset-0 w-full h-full opacity-0 cursor-pointer;
    }

    .price-tag {
        @apply absolute top-0 h-5 px-1.5 py-0.5 font-bold text-xs text-white rounded-bl-lg;
    }
}
